<template>  
  <div class="transit">
    <div class="ringtitle">
      <div class="tt">{{ringtitles[0]}}</div>
      <div class="tt">
        {{ringtitles[1]}}
      </div>
    </div>
    <div class="pilkl">
      <div class="pilklTitle">
        <div class="shanni left"></div>
        <div class="shaniIII">综合科室组</div>
        <div class="shanni right"></div>
      </div>
      <div class="sdwx">
        <div class="jujuye">
          <div class="keshi">
            <div>科室</div>
          </div>
          <div class="sss nnn">评分</div>
          <div class="sss ">名次</div>
        </div>
        <div class="jujuyess">
          <div class="huhu" v-for="(item,index) in departmentList" :key="index">
            <div>
              <div>{{item.name}}</div>
            </div>
            <div class="linkcenter">
              <div>{{item.one}}分</div>
            </div>
            <div class="linkcenter pai">{{index+1}}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="submit" @click="gosubmit()">
      开始下一组评分
    </div>
  </div>
</template>

<script>
import {reactive,onMounted,onUnmounted} from 'vue'
import { toRefs } from '@vue/reactivity'
import {useRoute, useRouter} from "vue-router";
import {getTodaySummary} from '../api/index.js'
export default {
  name: 'App',
  components: {
  },
  setup(){
    const obj = reactive(
      {
        ishint:true,
        ringtitles:['鹿城区综合行政执法局2022年第一场','述职评议擂台赛评分表'],
        departmentList:[
          {name:'指挥中心',one:'92'},
          {name:'办公室',one:'92'},
          {name:'组织人事科',one:'92'},
          {name:'监督考核科',one:'92'},
          {name:'宣传教育科',one:'92'},
          {name:'机关党委 (机关纪委)',one:'92'},
          {name:'计划财务科',one:'92'},
        ],
        keybord:[1,2,3,4,5,6,7,8,9,0,'.'],
        iskeybord:false,
        listindex:0,
        inputindex:0
      }
    )
    const router = useRouter();
    function gosubmit(){
      router.push({path:'/logins'});
    }
    onMounted(()=>{
      getprolist()
    })
    function getprolist(){
      var dd = localStorage.getItem('F_Year')
      obj.ringtitles[0] = '鹿城区综合行政执法局' + dd +'年第一场'
    }
    onUnmounted(() => {
    })
    return{
      ...toRefs(obj),
      gosubmit
    }
  },
  methods:{
  }
}
</script>

<style lang="less">
  @import '../css/phys.less';
</style>
